.grid-wrapper {
    margin: 0 auto;
    background-color: $secondary-color;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
        "grid-main"
        "grid-portfolio"
        "grid-contact"
        "grid-skills"
        "grid-footer";
} // End .grid-wrapper

.sec-main {
    // Site Grid Wrapper
    grid-area: grid-main;
    
    // sec-main Grid layout
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
        "m-header"
        "m-about";

    .sec-main__header {
        grid-area: m-header;
    }

    .sec-main__about-me {
        grid-area: m-about;
    }

    .sec-main__aside {
        grid-area: m-aside;
    }

    @include breakPoint(992px){
        display: grid;
        grid-template-columns: 33% auto;
        grid-template-rows: auto;
        grid-template-areas: 
            "m-header  m-about";
    } // End breakPoint(992px)
} // End .sec-main

.sec-portfolio {
    grid-area: grid-portfolio;

    // sec-portfolio Grid layout
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
        "p-heading"
        "p-projects";

    .sec-portfolio__heading {
        grid-area: p-heading;
    }

    .sec-portfolio__projects {
        grid-area: p-projects;
    }

    .sec-portfolio__carousel-ctrl-prev-button {
        grid-area: p-control-l;
    }
    
    .sec-portfolio__carousel-ctrl-next-button {
        grid-area: p-control-r;
    }

    @include breakPoint(992px){
        grid-template-columns: auto 2fr auto;
        grid-template-rows: auto minmax(500px,auto) auto;
        grid-template-areas: 
            "p-heading    p-heading         p-heading"
            "p-control-l  p-projects        p-control-r";
    } // End breakPoint(992px)

    @include breakPoint(1200px){
        grid-template-columns: .2fr 2fr .2fr;
    }

    @include breakPoint(1600px){
        grid-template-columns: .3fr 2fr .3fr;
    }

    @include breakPoint(1900px){
        grid-template-columns: .5fr 2fr .5fr;
    }
} // End .sec-portfolio

.sec-contact {
    grid-area: grid-contact;

    // sec-contact Grid layout
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
    "c-heading"
    "c-footer";

    .sec-contact__heading {
        grid-area: c-heading;    
    }

    .sec-contact__footer {
        grid-area: c-footer;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: 
        "c-email"
        "c-phone"
        "c-form"
        "c-social";

        @include breakPoint(992px){
            grid-template-columns: auto 1fr;
            grid-template-rows: auto;
            grid-template-areas: 
            "c-social c-email"
            "c-social c-phone"
            "c-social c-form";
        }

        @include breakPoint(1200px){
            grid-template-columns: auto 1fr;
            grid-template-rows: auto;
            grid-template-areas: 
            "c-social c-email"
            "c-social c-phone"
            "c-social c-form";
        }
    
        .contact-footer__email-container {
            grid-area: c-email;
        }
    
        .contact-footer__phone-container {
            grid-area: c-phone;
        }
    
        .contact-footer__form-container {
            grid-area: c-form;
        }

        .contact-footer__social-media {
            grid-area: c-social;
        }
    } // End &__footer
} // End .sec-contact

.sec-skills {
    grid-area: grid-skills;

    // sec-skills Grid layout
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
    "s-heading"
    "s-chart"
    "s-education";

    .sec-skills__heading {
        grid-area: s-heading;
    }

    .sec-skills__chart {
        grid-area: s-chart;
    }

    .sec-skills__education {
        grid-area: s-education;
    }

    @include breakPoint(768px){
        grid-template-areas: 
        "s-heading"
        "s-showcase"
        "s-chart"
        "s-education";

        .sec-skills__showcase {
            grid-area: s-showcase;
        }
    } // .sec-skills breakpoint 768px
} // End .sec-skills

.sec-footer {
    grid-area: grid-footer;

    // sec-footer Grid layout
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
    "f-contact"
    "f-copyright";

    .sec-footer__copyright {
        grid-area: f-copyright;
    }

    .sec-footer__contact {
        grid-area: f-contact;
    }
} // End .sec-footer

