.sec-skills {
  $self: &; // For Nested BEM support. Defines variable as ".sec-skills"
  @include sectionContainer;
  position: relative;

  @include breakPoint(992px) {
    background-color: $primary-color;
    background-image: url("../assets/images/backgrounds/skills-bg.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &__heading {
    @include sectionHeading;

    @include breakPoint(768px){
      display: flex;
      justify-content: center;
      align-items: center;
      color: $secondary-color;
    } // End heading for 768px

    @include breakPoint(992px){
      display: flex;
      justify-content: flex-end;
      align-items: center;
      background-color: transparent;
    } // End heading for 1200px


    & > i {
      display: none;

      @include breakPoint(768px){
        display: inline-block;
        color: $secondary-color;
        font-size: 2.5rem;
        margin: 0 2rem;
      }

      @include breakPoint(992px){
        display: inline-block;
        color: $secondary-color;
        font-size: 3rem;
        margin: 0 2rem;
      }
    }
  } // End heading

  // ORBITING SKILSS
  &__showcase {
    display: none;

    // Display the Orbiting Skills Animation on screens 768px width and up
    @include breakPoint(768px) {
      height: 400px;
      width: 100%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotateX($orbitAngle); // Gives the illusion of the orbit angle
      transform-style: preserve-3d; // preserves all the content within this (skills) as 3D

      /* Hidden orbit path of skills around the HTML image */
      .skills-orbit-path {
        // border: 1px dashed black;  // debugging positions of skills in orbit
        position: relative;
        width: calc(300px * #{$orbitPathScale-sm});
        height: calc(300px * #{$orbitPathScale-sm});
        border-radius: 100%;
        transform-style: preserve-3d;
        animation: spin-right $spinRate linear infinite;

        @include breakPoint(992px) {
          width: calc(300px * #{$orbitPathScale-lg});
          height: calc(300px * #{$orbitPathScale-lg});
        }

        /* Skills image counter rotation to keep upright */
        .skills-orbit-path--rotate-skills {
          position: absolute;
          height: 70px;
          width: 70px;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: rotateX(-95deg);
          transform-style: preserve-3d;
          animation: spin-left $spinRate linear infinite;
        }

        .skills-orbit-path__sql {
          bottom: 0;
          left: 45%;
          background-image: url("../assets/images/skills/logo-sql.svg");
          background-repeat: no-repeat;
          background-size: contain;
          border: 2px solid #4d4d4d;
          border-radius: 15px;

          @include breakPoint(992px){
            background-image: url("../assets/images/skills/logo-sql-secondary.svg");
            border-color: $secondary-color;
          }
        }

        .skills-orbit-path__bootstrap {
          bottom: 5%;
          left: 19%;
          background-image: url("../assets/images/skills/logo-bootstrap.svg");
          background-repeat: no-repeat;
          background-size: contain;

        }

        .skills-orbit-path__javascript {
          bottom: 26%;
          left: 4%;
          background-image: url("../assets/images/skills/logo-javascript.svg");
          background-repeat: no-repeat;
          background-size: contain;

        }

        .skills-orbit-path__jquery {
          top: 40%;
          left: 0%;
          background-image: url("../assets/images/skills/logo-jquery.svg");
          background-repeat: no-repeat;
          background-size: contain;

          @include breakPoint(992px){
            background-image: url("../assets/images/skills/logo-jquery-secondary.svg");
          }
        }

        .skills-orbit-path__reactjs {
          top: 16%;
          left: 8%;
          background-image: url("../assets/images/skills/logo-react.svg");
          background-repeat: no-repeat;
          background-size: contain;

        }

        .skills-orbit-path__atlassian {
          top: 3%;
          left: 23%;
          background-image: url("../assets/images/skills/logo-atlassian.svg");
          background-repeat: no-repeat;
          background-size: contain;

        }

        .skills-orbit-path__sass {
          top: -3%;
          left: 50%;
          background-image: url("../assets/images/skills/logo-sass.svg");
          background-repeat: no-repeat;
          background-size: contain;

        }

        .skills-orbit-path__mongodb {
          top: 6%;
          right: 18%;
          background-image: url("../assets/images/skills/logo-mongoDB.svg");
          background-repeat: no-repeat;
          background-size: contain;

        }

        .skills-orbit-path__git {
          top: 26%;
          right: 4%;
          background-image: url("../assets/images/skills/logo-git.svg");
          background-repeat: no-repeat;
          background-size: contain;

        }

        .skills-orbit-path__css3 {
          bottom: 43%;
          right: 0%;
          background-image: url("../assets/images/skills/logo-css3-alt.svg");
          background-repeat: no-repeat;
          background-size: contain;

        }

        .skills-orbit-path__nodejs {
          bottom: 21%;
          right: 7%;
          background-image: url("../assets/images/skills/logo-node-js.svg");
          background-repeat: no-repeat;
          background-size: contain;

        }

        .skills-orbit-path__php {
          bottom: 3%;
          right: 22%;
          background-image: url("../assets/images/skills/logo-php.svg");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      /* Center the HTML image at the center of orbit */
      .skills-showcase__html5 {
        position: absolute;
        height: 45%;
        width: 45%;
        transform: rotateX(-$orbitAngle);
        background-image: url("../assets/images/skills/logo-html5.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;

        @include breakPoint (992px){
          height: 50%;
          width: 50%;
        }
      }

      .skills-showcase__skill-names {
        position: absolute;
        bottom: -400px;
        width: 100%;
        text-align: center;
        transform: scaleY(2);

        .skills-showcase__skill-names--scroll::before {
          content: "SQL";
          font-size: 4rem;
          font-weight: 500;
          letter-spacing: 5px;
          color: darken($txt-color,30%);  // Want some separation of color between this text and the Skill Legend background color
          animation: scroll-skill-names $spinRate infinite 1s;

          @include breakPoint(992px) {
            color: $secondary-color;
          }
        } // End showcase-skill-names-scroll::before
      } // End showcase-skill-names 
    } // End showcase 768px breakpoint
  } // End showcase (Orbiting Skills)

  &__chart {
    @include sectionBody;

    @include breakPoint(992px) {
      padding-top: 50px;
    }

    #{$self}__skill-legend {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin: 4em -20px 1.5em -20px;
      padding: 1.5em 20px;
      background-color: $skills-banner-color;
      color: $secondary-color;
      position: sticky;
      top: 0;

      @include breakPoint(640px) {
        justify-content: space-evenly;
      }
 
      @include breakPoint(992px) {
        position: relative;
        background-color: transparent;
      }

      &::before {
        content: "";
        width: 25px;
        height: 15px;
        position: absolute;
        bottom: -15px;
        left: 0;
        background-color: darken($skills-banner-color, 8%);
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        z-index: -1;
      } // End &::before
      
      &::after {
        content: "";
        width: 25px;
        height: 15px;
        position: absolute;
        bottom: -15px;
        right: 0;
        background-color: darken($skills-banner-color, 8%);
        clip-path: polygon(0 0, 100% 0, 0 100%);
        z-index: -1;
      } // End &::after

      #{$self}__skill-legend-title {
        margin-bottom: 1rem;
        font-weight: 500;

        @include breakPoint(768px) {
          font-size: 1.2rem;
        }

        @include breakPoint(1200px) {
          font-size: 1.5rem;
        }
      } // End skill-legend-title

      #{$self}__skill-legend-icon {
        width: 40px;
        height: 40px;
        line-height: 42px;
        margin: 0 auto;
        border-radius: 50%;
        color: $secondary-color;

        @include breakPoint(1200px) {
          font-size: 1.2rem;
        }
      } // End skill-legend-icon

      #{$self}--skill-legend-proficient-icon {
        background-image: $skills-proficient-color;
      }
      #{$self}--skill-legend-intermediate-icon {
        background-image: $skills-intermediate-color;
      }
      #{$self}--skill-legend-advance-icon {
        background-image: $skills-advance-color;
      }
    } // End skill-legend

    #{$self}__skills {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      #{$self}__skill-card {
        width: 280px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid $secondary-color;
        border-radius: 5px;
        padding: 1rem 0;
        margin: 10px 5px;
        box-shadow: $boxShadow;

        @include breakPoint(992px) {
          box-shadow: none;
          background-color: $secondary-color;
          color: $txt-color;
        }

        #{$self}__skill-name {
          width: 50%;
          text-align: right;
          font-size: 1.2rem;
        }

        #{$self}__skill-level {
          width: 30px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          margin: 0 auto;
          border-radius: 50%;
          color: $secondary-color;
        }

        #{$self}--skill-level-proficient {
          background-image: $skills-proficient-color;
        }

        #{$self}--skill-level-intermediate {
          background-image: $skills-intermediate-color;
        }

        #{$self}--skill-level-advance {
          background-image: $skills-advance-color;
        }
      } // End skill-card
    } // End skills
  } // End chart
  
  &__education {
    // @include sectionBody;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 4em;

    #{$self}__education-heading {
      display: block;
      width: 100%;
      text-align: center;
      font-size: 2rem;
      letter-spacing: .2rem;
      background-color: $primary-color;
      color: $secondary-color;
      padding: 1rem;
      margin: 3rem 0 1.5rem 0;
      border-radius: 5px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        color: $txt-color;
        border: 1px solid $txt-color;
        box-shadow: $boxShadow;
      }

      @include breakPoint(640px){
        letter-spacing: .5rem;
      }

      @include breakPoint(992px){
        font-size: 2.5rem;
        color: $secondary-color;
        letter-spacing: .8rem;

        &::before {
          display: none;
        }
      }
    }

    #{$self}--certs {
      height: 100px;
      width: 190px;
      border-radius: 10px;
      background-color: #fff;
      margin: .7em;
      padding: 15px;
      box-shadow: $boxShadow;

      @include breakPoint(992px) {
        box-shadow: none;
      }
    }

    #{$self}--certs > img {
      width: 100%;
      height: 100%
    }

    #{$self}__mba-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      #{$self}__mba-icon {
        font-size: 2rem;
        line-height: 0;
        position: relative;
        top: 17px;
        left: 44px;
        transform: rotate(33deg);
        color: #b99c00;
      }

      #{$self}__mba-text {
        font-size: 3rem;
        font-weight: 500;
        text-decoration: none;
      }
    } // End mba-container
  } // End certs
} // End .sec-skills
