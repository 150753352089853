// Elements that use this Animation
// Header Section - Name Menu Icon
@keyframes grow-fs {
    0% {
      opacity: 0;
      height: 0;
      width: 0;
      border-radius: 50% 0 50% 50%;
    }
    100% {
      opacity: 1;
      height: 310px;
      width: 100vw;
      border-radius: 0;
    }
  } // End swing-in animation
  
  // Elements that use this Animation
  // Header Section - Name Menu Icon
  @keyframes shrink-hidden {
    0% {
      opacity: 1;
      height: 310px;
      width: 100vw;
      border-radius: 0;
    }
    100% {
      opacity: 0;
      height: 0;
      width: 0;
      border-radius: 50% 0 50% 50%;
    }
  } // End swing-out animation

  // Elements that use this Animation
  // .sec-projects__proj-cta - Project CTA Buttons
  // This is a work around for delaying the clickability of the CTA buttons when hidden
  @keyframes delay-pointer-events {
    0% {
      visibility: hidden;
      
    }
    50% {
      visibility: hidden;
    }
    100% {
      visibility: visible;
    }
  } // End delay-pointer-events animation

// Elements that use this Animation
// Contact Section - Phone
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
} // End pulse animation

// Elements that use this Animation
// Skills Showcase Orbiting Skills (the orbit rotation)
@keyframes spin-right {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
} // End spin-right animation

// Elements that use this Animation
// Skills Showcase Orbiting Skills ( counter rotation of the skills images )
@keyframes spin-left {
  0% {
    transform: rotateX(-90deg) rotateY(-360deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
  }
} // End spin-left animation

// Elements that use this Animation
// Skills Showcase Scrolling Skill Names
@keyframes scroll-skill-names {
// 12 skill divided by 100 = 8.3 which is the percent increment
// Note state at 0% and 100% the skill will be the same 
  0% {
      content: 'SQL';
  }
  8.3% {
      content: 'PHP';
  }
  16.6% {
      content:'NodeJS';
  }
  24.9% {
      content:'CSS';
  }
  33.2% {
      content:'Git';
  }
  41.5% {
      content:'MongoDB';
  }
  49.8% {
      content:'Sass';
  }
  58.1% {
      content:'Atlasian Admin';
  }
  66.4% {
      content:'ReactJS';
  }
  74.7% {
      content:'jQuery';
  }
  83% {
      content:'JavaScript';
  }
  91.3%{
      content:'Bootstrap';
  }
  100% {
      content:'SQL';
  }
} // End scroll-skill-names animation


// Elements that use this Animation
// Scroll to top of page link at bottom of the site
@keyframes slow-blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
} // End slow-blink animation

@keyframes slide-in-from-right {
  0% {
    opacity: 0;
    right: -300px
  }
  100% {
    opacity: 1;
    right: 0;
  }
}