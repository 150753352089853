// MAIN SASS STYLES ENTRY - Contains "high level" styles that apply HTML and BODY tags as well as imports to other partials
/* Using BEM CSS Naming Standard along with Sass Nesting*/

// ********* [START] IMPORT OF PARTIALS *********
@import "reset";
@import "variables";
@import "mixins";
@import "layout";
@import "animations";

// Section Styles
@import "sec-main";
@import "sec-portfolio";
@import "sec-contact";
@import "sec-skills";
@import "sec-footer";

// ********* [END] IMPORT OF PARTIALS *********

// ********* [START] Import Fonts *********
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');  // Poppins Google Font

// ********* [END] Import Fonts *********


// Defaults for the page unless explicitly defined for an element(s)
html {
  scroll-behavior: smooth;
  font-family: 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: $txt-color;
}


//  Screen Reader only content. Provide better Sematics for SR's
.sr-only {
  clip: rect(1px,  1px,  1px,  1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

body {
  background-color: #686868;
  overflow-x: hidden;

}

.alert {
  display: block;
  margin: 20px;
  padding: 10px 20px;
  background-color: $standout-color;
  color: #000;
  border-radius: 10px;
  text-align: center;
}

a.alert {
  text-decoration: none;
  transition: all 1s ease-in-out;
  
  &:hover{
    transform: scale(1.05);
    background-color: darken($standout-color, 4%);
    color: lighten(#000, 20%);
  }

}

.grid-wrapper {
  max-width: 1920px;


  .to-top-of-page {
    // Link to take you back to the top of the page
    display: block;
    position: fixed;
    width: 40px;
    height: 50px;
    line-height: 50px;
    bottom: 120px;
    right: 0;
    padding-left: 7px;
    color: $secondary-color;
    font-size: 1.6rem;
    text-align: center;
    background-color: $btn-bg-color;
    border-radius: 50% 0 0 50%;
    border: 1px solid $secondary-color;
    opacity: 0;
    transition: opacity 1s linear;
    z-index: 1;

    @include breakPoint(992px){
      width: 50px;
      height: 60px;
      line-height: 60px;
      font-size: 2rem;
    }
  } // END to-top-of-page
   
  .to-top-of-page.to-top-of-page--trigger {
    opacity: 1;
  }

  .to-top-of-page--at-bottom {
    width: 130px;
    height: 60px;
    bottom: 120px;
    transform: translateX(calc(-50vw + 65px));
    margin-left: -65px;   // True center the element by moving margin left negative half the width (130/2 = 65px)
    color: $txt-color;
    font-size: 3rem;
    padding: .4rem 0 0 0;
    background-color: #fff;
    border-radius: 50% 50% 0 0;
    transition: all 1s linear;
    
    & > i {
      animation: slow-blink 1s linear infinite;
    }
  } // END to-top-of-page--at-bottom



}