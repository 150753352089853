.sec-main {
  $self: &; // For Nested BEM support. Defines variable as ".sec-main"
  @include sectionContainer;

  @include breakPoint(992px) {
    padding: 0;
    min-height: 100vh;
  }

  @include breakPoint(992px) {
    background-image: url("../assets/images/backgrounds/main-bg.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &__header {
    @include sectionHeading;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;

    @include breakPoint(992px) {
      flex-direction: column;
      margin: 0;
    }

    .header-brand {
      padding-right: 60px;
      text-align: left;

      @include breakPoint(640px) {
        // Mobile Landscape
        padding-left: 20px;
      }

      @include breakPoint(992px){
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.7rem;
        padding: 0 0 0 32px;
        margin: 15px;
      }

      .header-avatar {
        display: none;

        @include breakPoint(992px){
          display: block;
          border-radius: 50%;
          transform: scale(0.8);
        }

        @include breakPoint(1200px) {
          transform: scale(1);
        }
      } //End header-avatar


      .header-name {

        @include breakPoint(992px){
          text-align: center;
          position: relative;
          top: -10px;
          font-size: 2rem;
        }

        @include breakPoint(1200px) {
          font-size: 2.2rem;
          position: inherit;
          margin-top: .8rem;
        }
      } // End header-name
    } // End header-brand

    .header-nav {
      
      @include breakPoint(640px) {
        // Mobile Landscape
        padding-right: 20px;
      }

      @include breakPoint(640px) {
        height: 100%;
      }

      .nav-menu-icon {
        position: relative;
        z-index: 100;
        font-size: 3rem;
        cursor: pointer;

        @include breakPoint(992px) {
          display: none;
        }
      } // End header-nav-menu-icon

      .nav-menu {
        display: none;
        list-style-type: none;
        font-size: 1rem;
        color: $txt-color;

        @include breakPoint(992px){
          display: block; 
          background: none;
          font-size: 1.2rem;
        }
      } // End header-nav-menu

      .nav-menu--active {
        background: $primary-color;
        position: absolute;
        display: block;
        border-radius: 0;
        right: 0;
        top: 0;
        margin: 0;
        padding: 1em 0;
        text-align: center;
        transform-origin: top right;
        animation: grow-fs 0.5s linear forwards;
      } // End header-nav-menu--active

      .nav-menu--deactivated {
        transform-origin: top right;
        animation: shrink-hidden 0.5s linear forwards;
      } // End header-nav-menu--deactivated

      .nav-item {
        
        #nav-link-about {
          font-weight: 700;

          @include breakPoint(992px){
          color: $standout-color;  // When ever you can see the "About" link that means you're already on the About/Main section
          }
        }

        .nav-link {
          // color: $txt-color;
          color: $secondary-color;
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: 3px;
          display: block;
          padding: 1.5rem 3rem;

          @include breakPoint(992px){
            color: $secondary-color;
            border: 1px solid #fff;
            position: relative;
            padding: 10px 10px;
            margin: 1.5rem 3rem;
            transition: all .5s;

            &:hover { 
              color: $primary-color !important;
              background-color: $standout-color;
              border: none;
            }

            &::before {
              content: '';
              position: absolute;
              bottom: 12px;
              left: 12px;
              width: 12px;
              height: 12px;
              border: 3px solid $standout-color;
              border-width: 0 0 3px 3px;
              opacity: 0;
              transition: all 0.3s;
            }

            &:hover::before{
              opacity: 1;
              bottom: -8px;
              left: -8px;
            }

            &::after {
              content: '';
              position: absolute;
              top: 12px;
              right: 12px;
              width: 12px;
              height: 12px;
              border: 3px solid $standout-color;
              border-width: 3px 3px 0 0;
              opacity: 0;
              transition: all 0.3s;
            }

            &:hover::after {
              opacity: 1;
              top: -8px;
              right: -8px;
            }
          } // End header-nav-link 992px breakpoint
        } // End header-nav-link
      } // End header-nav-item
    } // End header-nav
  } // End header

  &__about {
    @include sectionBody;
    
    @include breakPoint(640px) {
      margin: auto;
    }

    @include breakPoint(768px) {
      margin: 2rem auto;
    }

    @include breakPoint(992px) {
      @include sectionContainer;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    @include breakPoint(1200px) {
      padding: 0 10em;
    }

    .about__title {
      text-align: center;

      .about__title-primary {
        font-size: 1.9rem;
        letter-spacing: 3px;
        
        @include breakPoint(992px) {
          font-size: 2.2rem;
        }

        @include breakPoint(1200px) {
          font-size: 2.6rem;
        }
      } // End about-title-primary

      .about__title-ampersand {
        font-weight: 500;

        @include breakPoint(992px) {
          font-size: 1.8rem;
        }

        @include breakPoint(1200px) {
          font-size: 2.2rem;
        }

      } // End about-title-ampersand

      .about__title-secondary {
        font-weight: 600;
        letter-spacing: 3px;
        
        @include breakPoint(992px) {
          font-size: 1.8rem;
        }

        @include breakPoint(1200px) {
          font-size: 2.2rem;
        }
      } // End about-title-secondary

      
    } // End about-title

    .about__me {
      padding: 20px 0;
      font-size: 0.9rem;
      letter-spacing: 1px;
      text-align: justify;
      max-width: 60ch;
      margin: auto;


      @include breakPoint(640px) {
        // Mobile Landscape
        padding: 20px 20px;
        margin: 1rem auto;
      }

      @include breakPoint(768px) {
        font-size: 1rem;
        padding: 20px 0; 
        margin: 2rem auto;
      }

      @include breakPoint(992px) {
        letter-spacing: 3px;  
        position: relative;
        top: -5%; 
      }

      @include breakPoint(1200px) {
        font-size: 1.2rem;
      }
    } // End about-me
  } // End "about"

  .about__resume {
    padding: 5px 0;
    text-align: center; // center the button

    @include breakPoint(640px) {
      text-align: right;
    }

    .about__cta-dl-resume {
      @include newButton(10px, 20px, normal, 1rem, 20px);

      @include breakPoint(768px) {
        @include newButton(12px, 22px, normal, 1.2rem, 22px);
      }

      @include breakPoint(992px) {
        @include newButton(15px, 25px, normal, 1.3rem, 25px);
      }
    } // End cta-dl-resume
  } // End aside
} // END .sec-main
