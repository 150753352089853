// Create Media Queries based on the screen with Passed
// Screen-widths should be 640px, 768px, 1920px
@mixin breakPoint($screenWidth) {
  @media only screen and (min-width: $screenWidth) {
    @content;
  }
} // End breakPoint

// Standardize on section container styles for consistency
@mixin sectionContainer {
  font-size: 1rem;
  padding: 0 20px 20px 20px; // left, right, and bottom padding for consistency spacing

  @include breakPoint(768px){
    padding: 0 60px 20px 60px;
  }

  @include breakPoint(992px){
    padding: 0 60px 20px 80px;
  }

  @include breakPoint(1200px){
    padding: 0 90px 20px 90px;
  }

  @include breakPoint(1400px){
    padding: 0 120px 20px 120px;
  }
} // End sectionContainer

// Standardize on section HEADINGS styles for consistency
@mixin sectionHeading {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0 -20px;
  padding: 15px 20px;  // All around padding
  font-size: 2rem;
  font-weight: bold;
  background-color: $primary-color; // fall back
  background-image: $heading-bg-color;
  color: $secondary-color;
  text-align: center;


  @include breakPoint(768px){
    padding: 20px 20px;
    margin: 0 -60px;
  }

  @include breakPoint(992px){
    padding: 30px 20px;
    background-image: none;
    overflow-x: hidden;
    position: relative;

    & > i, & > h2 {
      position: relative;
      right: -300px;
    }

    .slide-in-from-right{
      animation: slide-in-from-right .5s linear forwards;
    }
  }

  @include breakPoint(1200px){
    margin: 0;
  }
} // End sectionHeading

// Standardize on section body content styles for consistency
@mixin sectionBody {
  padding-top: 20px;  // since the sectionContainer & sectionHeading control a majority of the spacing only fine tune is needed
}

// Button Generator
@mixin newButton($padding-height, $padding-width, $line-height, $font-size, $border-radius: 0) {
  display: inline-block; // Make the link a block level element so other elements are aware of it's full height and width
  padding: $padding-height $padding-width;
  font-size: $font-size;
  line-height: $line-height; // centers the button text vertically
  border-radius: $border-radius;
  background: $btn-bg-color;
  color: $btn-txt-color;
  text-decoration: none;
  text-align: center;
  border-width: 1px;
  border-style: solid;
  border-color: $btn-txt-color $txt-color $txt-color $btn-txt-color;

  &:hover {
    color: $standout-color;
  }

  &:active {
    border-color:  $txt-color $btn-txt-color $btn-txt-color $txt-color;
  }
} // End newButton

  // Project Card Carousel Zoom
  @mixin zoomCard($zoom){
    &:nth-child(1) { transform: rotateY(0deg) translateZ( $zoom); }
    &:nth-child(2) { transform: rotateY(45deg) translateZ( $zoom); }
    &:nth-child(3) { transform: rotateY(90deg) translateZ( $zoom); }
    &:nth-child(4) { transform: rotateY(135deg) translateZ( $zoom); }
    &:nth-child(5) { transform: rotateY(180deg) translateZ( $zoom); }
    &:nth-child(6) { transform: rotateY(225deg) translateZ( $zoom); }
    &:nth-child(7) { transform: rotateY(270deg) translateZ( $zoom); }
    &:nth-child(8) { transform: rotateY(315deg) translateZ( $zoom); }
  } // End zoomCard