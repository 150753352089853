.sec-contact {
  $self: &; // For Nested BEM support. Defines variable as ".sec-contact"
  @include sectionContainer;

  @include breakPoint(992px) {
    background-image: url("../assets/images/backgrounds/contact-bg.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
  }

  &__heading {
    @include sectionHeading;

    @include breakPoint(768px){
      display: flex;
      justify-content: center;
      align-items: center;
      color: $secondary-color;
    } // End heading for 768px

    @include breakPoint(992px) {
      justify-content: flex-end;
      background-color: transparent;
      color: $secondary-color;
    } // End heading for 1200px

    & > i {
      display: none;

      @include breakPoint(768px){
        display: inline-block;
        color: $secondary-color;
        font-size: 2.5rem;
        margin: 0 2rem;
      }

      @include breakPoint(992px) {
        display: inline-block;
        color: $secondary-color;
        font-size: 3rem;
        margin: 0 2rem;
      }
    } // end of & > i (icon)
  } // End of &__heading

  &__footer {

    .contact-footer__email-container {
      margin: 4em auto 2em auto;
      text-align: center;

      .contact-footer__email-icon-cta {
        text-decoration: none;
        color: $btn-bg-color;

        @include breakPoint(992px) {
          display: flex;
          justify-content: center;
        }

        .contact-footer__email-icon {
          font-size: 3.5rem;
          animation: slow-blink 2s linear infinite;

          &:hover {
            animation: none;
            transform: scale(1.1);
          }

          @include breakPoint(992px) {
            color: $secondary-color;
            font-size: 4rem;
          }
        } // End email-icon
      } // end Email-icon-cta

      .contact-footer__email-address {
        margin: .5em auto;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.3rem;

        @include breakPoint(640px) {
          // Mobile Landscape
          font-size: 1.5rem;
          font-weight: 600;
        }

        @include breakPoint(992px) {
          display: inline-block;
          font-size: 1.8rem;
          padding-left: 1.5rem;
          color: $secondary-color;
        }
      } // End email-address
    } // End Email Container

    .contact-footer__phone-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 2em auto;

      @include breakPoint(992px) {
        flex-direction: row;
      }

      .contact-footer__phone-icon-cta {
        display: block;
        height: 100px;
        width: 100px;
        position: relative;
        border-radius: 50%;
        background-color: $green-color;

        .contact-footer__phone-icon {
          width: 100%;
          font-size: 1.8rem;
          text-align: center;
          line-height: 100px;
          color: $btn-txt-color;
          transform: rotateY(180deg);
        } // End phone-icon

        &::before,
        &::after {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border: 1px solid $green-color;
          border-radius: 50%;
          transform: scale(1.5);
          animation: pulse 1.8s linear infinite;
        }

        &::after {
          animation-delay: 0.5s;
        }
      } // End phone-icon-cta
    } // End phone_container

    .contact-footer__form-container {
      display: flex;
      justify-content: center;
      margin: 2em 0;

      .contact-footer__form {
        width: 100%;
        max-width: 600px;
        min-width: 290px;
        justify-self: center;
        background-image: $form-bg-color;
        color: $secondary-color;
        border-radius: 5px;
        padding: 20px;
        display: flex;
        flex-direction: column;

        & > * {
          margin: 10px 0;
        }

        & > label > * {
          border-radius: 5px;
          padding: 10px;
          display: block;
          background-color: $secondary-color;

          &:focus {
            background-color: white;
          }
        } // End & > label > *  (child elements of the labels / input fields)

        &-name-input {
          width: 75%;
        }

        &-email-input {
          width: 75%;
        }

        &-message-input {
          width: 100%;
          height: 100px;
          resize: none;
        }

        &-submit-button {
          align-self: flex-end;
          border: 2px solid $primary-color;
          background-color: $secondary-color;
          color: $primary-color;
          top: -2px;
          padding: 15px 25px;
          border-radius: 10px;
          font-size: 1.2rem;
          font-weight: bold;
          letter-spacing: 3px;
          text-transform: uppercase;
          cursor: pointer;
          transition: color 0.5s;
          position: relative;
          overflow: hidden;

          &:hover {
            color: $btn-txt-color;
            border-color: $btn-txt-color;
            z-index: 2;
          }

          &:active {
            top: 0;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0%; // want to later use for animate grow
            background: $btn-bg-color;
            z-index: -1;
            border-radius: 0 0 50% 50%;
            transition: height 1.2s;
          }

          &:hover::before {
            height: 190%;
          }
        } // End Submit Button
      } // End form
    } // End .contact-form-container

    .contact-footer__social-media {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      text-align: center;
      margin: 1em 0;

      @include breakPoint(992px) {
        flex-direction: column;
        justify-content: center;
        position: absolute;
        left: 0;
        background-color: #fff;
        border-radius: 0 15px 15px 0;
        padding: 10px 10px;
        margin-top: 40px;
      }

      @include breakPoint(1200px) {
        padding: 10px 25px;
        margin-top: 40px;
      }

      .social-icon-container {
        width: 45px;
        height: 45px;
        font-size: 1.5rem;
        position: relative;
        border-radius: 50%;
        margin: 0.5rem;


        @include breakPoint(768px) {
          width: 65px;
          height: 65px;
          font-size: 2rem;
        }

        @include breakPoint(992px) {
          font-size: 2.3rem;
          margin: 0.6rem 1.5rem;
          padding: 10px;
          background-color: transparent;
        }

        .social-icon {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          line-height: 45px;
          color: #fff;

          @include breakPoint(768px) {
            line-height: 65px
          }
        } // End social-icon
      } // End social-icon-container

      .contact-footer__github-container {
        background-color: #24292E;

        @include breakPoint(992px) {
          background-color: transparent;
          transition: background-color 1.5s linear;

        &:hover {
          background-color: #24292E;
        }

        &:hover .contact-footer__github-icon {
          transform: rotateY(180deg);
          opacity: 0;
        }

        &:hover .contact-footer__github-silhouette-icon {
          transform: rotateY(180deg);
          opacity: 1;
        }
      } // End github-container for 992px

      .contact-footer__github-icon {
          color: #24292E;
          opacity: 0;
          
          @include breakPoint(992px) {
            opacity: 1;
            transition: all 1s linear;
          }
        } // End github-icon

        .contact-footer__github-silhouette-icon {
          opacity: 1;

          @include breakPoint(992px) {
            opacity: 0;
            transition: all 1s linear .2s;
          }
        } // End github-silhouette-icon
      } // End github-container

      .contact-footer__linkedin-container {
        background-color: #2177B4;

        @include breakPoint(992px) {
          background-color: transparent;
          transition: background-color .5s linear;

          &:hover {
            background-color: #2177B4;
          }

          &:hover .contact-footer__linkedin-icon {
            font-size: 1.8rem;
            color: #fff;
          }
        } // End linkedin-container for 992px

        .contact-footer__linkedin-icon {
          color: #fff;

          @include breakPoint(992px) {
            color: #2177B4;
            opacity: 1;
            transition: all .5s linear .2s;
          }
        } // End linkedin-icon
      } // End linkedin-container

      .contact-footer__twitter-container {
        background-color: #30A1F2;

        @include breakPoint(992px) {
          background-color: transparent;
          transition: background-color 1s linear;

          &:hover {
            background-color: #30A1F2;
          }

          &:hover .contact-footer__twitter-icon {
            opacity: 0;
            transform: translate(70px, -20px);
          }

          &:hover .contact-footer__twitter-icon-fly-in {
            opacity: 1;
            transform: translate(0, 0);
          }
        } // End twitter-container for 992px

        .contact-footer__twitter-icon {

          @include breakPoint(992px) {
            color: #30A1F2;
            transition: all .5s linear;
          }
        } // End twitter-icon

        .contact-footer__twitter-icon-fly-in {
          opacity: 0;
          transform: translate(-70px, 20px);
          transition: all .5s linear;
        } // End twitter-icon-fly-in
      } // End twitter-container 

      .contact-footer__facebook-container {
        background-color: #4267B2;

        @include breakPoint(992px) {
          background-color: transparent;
          transition: background-color 1s linear;

          &:hover {
            background-color: #4267B2;
          }

          &:hover .contact-footer__facebook-icon {
            opacity: 0;
          }

          &:hover .contact-footer__facebook-thumbs-up-icon {
            opacity: 1;
            transform: rotate(0deg);
          }
        } // End facebook-container for 992px

        .contact-footer__facebook-icon {

          @include breakPoint(992px) {
            color: #4267B2;
            transition: opacity 1s linear;
          }
        }
        
        .contact-footer__facebook-thumbs-up-icon {
          font-size: 1.8rem;
          opacity: 0;
          transform: rotate(45deg);
          transition: all .7s ease-out;
        }
      } // End facebook-container

      .contact-footer__codepen-container {
        background-color: #000;

        @include breakPoint(992px) {
          background-color: transparent;
          transition: background-color 1s linear;
          perspective: 500px;

          &:hover {
            background-color: #000;
          }

          &:hover .contact-footer__codepen-icon {
            color: #fff;
            transform: translateZ(50px) rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
          }
        } // End codepen-container 992px 

        .contact-footer__codepen-icon {
            
            @include breakPoint(992px) {
              color: #000;
              transition: all 1.5s linear;
            }
          } // End codepen-icon
      } // End codepen container
    } // End social-media
  } // End of &__footer
} // End of .sec-contact

