// Theme Colors
$primary-color: #042C71; 
$secondary-color: #eeeeee;
$txt-color: #737373;
$standout-color: #F8D605;
$heading-bg-color: linear-gradient(to top right, $primary-color,#6A8AC1);
$btn-bg-color: #0B6AB0;
$btn-txt-color: #fff;
$red-color: #ff0000;
$green-color: #008a00;
$purple-color: #800080;
$skills-banner-color: #0B6AB0;
$skills-proficient-color: radial-gradient(circle at top right, #F36B1C,#a34813);
$skills-intermediate-color: radial-gradient(circle at top right,#c56fc5,$purple-color);
$skills-advance-color: radial-gradient(circle at top right,#9bbb9b, $green-color);
$form-bg-color: linear-gradient(to bottom left,$skills-banner-color,$primary-color);

// *** Basic reusable variables ***

// Box Shadow Default
$boxShadow: 5px 10px 10px;

// Skills Orbit Controls
$orbitPathScale-lg: 2.1;
$orbitPathScale-sm: 1.7;
$orbitAngle: 70deg;
$skillImgScale: .5;
$spinRate: 30s;